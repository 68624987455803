body {
    font-family: "Source Sans Pro", system-ui, sans-serif;
    margin: 0;
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: unset;
}

textarea {
    resize: none;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open.overlay {
    display: grid;
    justify-items: center;
    align-items: center;
}

.ReactModal__Content.ReactModal__Content--after-open {
    width: 60%;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.ReactModal__Content.ReactModal__Content--after-open:focus {
    outline: 0;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.mobile {
    display: none !important;
}

@media (max-width: 800px) {
    .not-mobile {
        display: none !important;
    }
    .mobile {
        display: block !important;
    }
    .ReactModal__Content.ReactModal__Content--after-open {
        width: 90%;
    }
}
